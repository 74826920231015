<template>
  <div class="home bg-red">
    <Splash class="bg-red"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Splash from '@/components/Splash.vue'

export default {
  name: 'Home',
  components: {
    Splash
  }
}
</script>
